import store from '../store'
import i18n from '../i18n'


const beforeFilter = (to, from, next) => {
    // If we append &locale= to the query string, we want to store it once
    // (so we don't need to pass it to every subsequent links)
    if (to.query.locale) {
        console.log("to.query.locale = " + to.query.locale)
        i18n.locale = to.query.locale
    }

    if (!to.query.wb_id && !!from.query.wb_id) {
        const include_charts = to.query.include_charts || from.query.include_charts
        next({
            name: to.name,
            params: to.params,
            query: {
                ...to.query,
                wb_id: from.query.wb_id,
                ...(include_charts ? { include_charts } : {}),
            }
        })
    } else if (!to.query.user && !!from.query.user) {
        next({ name: to.name, params: to.params, query: { ...to.query, user: from.query.user } })
    } else {
        // NOTE: we prevent loading every time we navigate (e.g. from Home to FC Advise),
        // so we load only the first time (when store.getters.pool is null)
        if (to.meta.auth === 'user' && !store.getters.pool) {
            store.dispatch('startLoading')
            store.dispatch('init', {
                wbId: to.query.wb_id || from.query.wb_id,
                userId: to.query.user || from.query.user,
                loadLab: true, // We always load as we now only load once
            }).then(({ user, pool }) => {
                store.dispatch('stopLoading')
                if (user) {
                    if (to.meta.pool) {
                        if (pool) {
                            next()
                        } else {
                            next({ name: 'error' })
                        }
                    } else {
                        next()
                    }
                } else {
                    next({ name: 'error' })
                }
            })
        } else {
            next()
        }
    }
}

const afterFilter = async (to, from) => {
    if (from.name) {
        store.dispatch('setPrevRoute', from)
    }
}

export {
    beforeFilter,
    afterFilter
}
