<template>
    <advice-list :no-number="object.advices.length === 1">
        <advice-list-card v-for="item in object.advices" :key="item.text || item">
            <div class="product-suggestion" v-if="typeof item === 'object'">
                <div class="product-info">
                    <div>{{ item.text }}</div>
                    <div class="product-info-details" v-if="item.product">
                        <img :src="item.product.thumb" alt="">
                        <div>
                            <p>{{item.product.name}}</p>
                            <p>$0.00</p>
                        </div>
                    </div>
                </div>
                <div v-if="item.button">
                    <w-button type="circle" size="lg" class="yummo-bold font-lg" @click="go(item)">
                        <w-translate-text :text="item.button.label" :fromLanguage="'en'" :toLanguage="locale"></w-translate-text>
                    </w-button>
                </div>
            </div>
            <template v-else>
                <w-translate-text :text="item" :fromLanguage="'en'" :toLanguage="locale"></w-translate-text>
            </template>
        </advice-list-card>
    </advice-list>
</template>

<script>

export default {
    name: 'HtmlAnswer',
    props: {
        object: {
            type: Object,
            required: true
        },
        locale: {
            type: String,
            default: 'en'
        }
    },
    created () {
        console.log("this.locale ", this.locale);
    },
    methods: {
        go (item) {
            if (item.button.onClick) {
                item.button.onClick()
            }
            if (item.button.label === 'Edit acid type'){
                this.$gtag.event('Edit Acid Type');
            }
            if (item.button.label === 'Edit chlorine type'){
                this.$gtag.event('Edit Chlorine Type');
            }
            this.$router.push(item.button.link).catch(e => e)
        }
    }
}
</script>

<style scoped>
    .btn.font-lg {
        line-height: 20px;
    }
</style>
