<template>
    <w-modal :open="open" @cancel="cancel" @back="cancel">
        <div v-if="question" class="d-flex">
            <div class="badge">{{ question.badge }}</div>
            <div>
                <h3><translate :text="question.desc"/></h3>
                <p><translate :text="question.title"/></p>
                <w-select-list v-model="selectValue" :inverse="true" :options="question.questions.map(({ id, name }) => ({ value: id, name: (name == '[OTHER]') ? $t('questions.other') : name }))"/>
                <validation-provider ref="observer" v-slot="{ errors, invalid }" :name="question.key">
                    <div v-show="showInput">
                        <label for="modalInput"><translate :text="question.label"/></label>
                        <input required ref="input" id="modalInput" class="inverse" :placeholder="$t('questions.value')"
                               :type="question.input_type" :name="question.key" :class="{'is-invalid': errors[0]}"
                               :min="question.input_min" :max="question.input_max" :step="question.input_step"
                               v-model="inputValue">
                        <div :class="errors[0] ? 'd-block' : 'd-none'" class="invalid-feedback">
                            {{ $t("questions.invalidInput", { "min": question.input_min, "max": question.input_max  }) }}
                        </div>
                    </div>
                    <w-button class="float-right mt-5" type="circle" :disabled="processing || !selectValue || (showInput && invalid)" @click="save">{{ $t("button.save") }}</w-button>
                </validation-provider>
            </div>
        </div>
    </w-modal>
</template>

<script>
import { lambda } from '../amplify'
import { ValidationProvider } from 'vee-validate'
import Translate from './Translate'

export default {
    name: 'SubSequentModal',
    components: { ValidationProvider, Translate },
    props: {
        question: {
            required: true,
            type: Object | undefined
        },
        open: {
            required: true,
            type: Boolean
        }
    },
    data () {
        return {
            inputValue: '',
            selectValue: '',
            processing: false
        }
    },
    computed: {
        wb () {
            return this.$store.getters.waterBody
        },
        showInput () {
            return this.selectValue === this.question.questions.find(item => item.value === '[INPUT]').id
        }
    },
    methods: {
        async save () {
            this.processing = true
            try {
                const answer = this.question.questions.find(item => item.id === this.selectValue)
                const { valid } = await this.$refs.observer.validate()
                if (answer.value === '[INPUT]' && !valid) return
                const value = answer.value === '[INPUT]' ? this.inputValue : answer.value
                const userId = this.$route.query.user || this.$store.getters.userId
                const waterBodyId = this.wb.waterBodyId
                const { code } = await lambda('updateWaterBody', {
                    waterBody: { userId, waterBodyId, [this.question.key]: value }
                })
                if (code !== 'OK') return
                await this.$store.dispatch('init', { wbId: this.$route.query.wb_id, userId: this.$route.query.user })
                this.$emit('saved')
            } finally {
                this.processing = false
            }
        },
        cancel () {
            this.$emit('cancel')
        }
    },
    watch: {
        open: {
            handler (val) {
                if (val) {
                    const value = this.wb[this.question.key]
                    const answer = this.question.questions.find(item => item.value === value)
                    if (answer) {
                        this.selectValue = answer.id
                    } else if (!answer && value) {
                        this.selectValue = this.question.questions.find(item => item.value === '[INPUT]').id
                        this.inputValue = value
                    }
                } else {
                    this.selectValue = ''
                    this.inputValue = ''
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
