<template>
<span>{{ translatedText }}</span>
</template>

<script>
import { TranslateClient, TranslateTextCommand } from "@aws-sdk/client-translate";
import Auth from "@aws-amplify/auth";

export default {
    name: 'TranslateText',
    props: {
        text: {
            type: String,
            default: ''
        },
        fromLanguage: {
            type: String,
            default: 'en'
        },
        toLanguage: {
            type: String,
            default: 'en'
        },
    },
    data () {
        return {
            translatedText: ''
        }
    },

    created () {
        console.log("this.fromLanguage this.toLanguage", this.fromLanguage, this.toLanguage);
        if (this.fromLanguage == this.toLanguage || this.text.trim() == '') {
            // Nothing to translate
            this.translatedText = this.text;
        } else {
            // Get credentials via Amplify
            Auth.currentUserCredentials().then((credentials) => {
                // Call AWS API to translate
                const client = new TranslateClient({
                    region: "us-west-2", // TODO: remove hardcode
                    credentials: credentials
                });

                const params = {
                    Text: this.text,
                    SourceLanguageCode: this.fromLanguage,
                    TargetLanguageCode: this.toLanguage,
                    TerminologyNames: [
                        'test'
                    ],
                    Settings: {
                        Formality: 'FORMAL'
                    }
                };
                const command = new TranslateTextCommand(params);

                // console.log("CALLING API TRANSLATE: ", params);
                client
                .send(command)
                .then((data) => {
                    // console.log("API TRANSLATED: ", data);
                    this.translatedText = data.TranslatedText;
                })
                .catch((error) => {
                    // console.log("API ERROR: ", error);
                })
                .finally(() => {
                    // finally.
                });

            });
        }
    }
}
</script>

<style scoped>

</style>
