<template>
    <div class="chart-box">
        <div class="y-axis" v-if="config.queryParam === 'ph'">
            <div class="point" style="opacity: .5;">8.5</div>
            <div class="point" style="top:22%;font-size: 18px">7.7</div>
            <div class="point" style="top:50%;font-size: 18px">7.6</div>
            <div class="point" style="top:80%;font-size: 18px">7.5</div>
            <div class="point" style="top:100%;opacity: .5;">6.5</div>
        </div>
        <div class="y-axis" v-else-if="config.queryParam === 'fc'">
            <div class="point" style="">10</div>
            <div class="point" style="top:20%;font-size: 18px">6.4</div>
            <div class="point" style="top:50%;">4</div>
            <div class="point" style="top:80%;font-size: 18px">2.6</div>
            <div class="point" style="top:100%;">0</div>
        </div>
        <div class="y-axis" v-else>
            <div class="point red-max">{{ ranges.RED_MAX }}</div>
            <div class="point green-max">{{ ranges.GREEN_MAX }}</div>
            <div class="point normal">{{ ranges.GREEN_NORMAL }}</div>
            <div class="point green-min">{{ ranges.GREEN_MIN }}</div>
            <div class="point red-min">{{ ranges.RED_MIN }}</div>
        </div>
        <Graphic class="chart" :height="270" :chart-data="chartData" :options="options"/>
    </div>
</template>

<script>
import Graphic from './Graphic'
import options, { ranges } from './options'
import deepmerge from 'deepmerge'
import { format } from 'date-fns'

export default {
    name: 'Chart',
    components: { Graphic },
    props: {
        data: {
            required: true,
            type: Array
        },
        labels: {
            required: true,
            type: Array
        },
        config: {
            type: Object,
            required: true
        },
        bgColors: {
            type: Array | String,
            default: '#23B8DC'
        }
    },
    computed: {
        dataFiltered () {
            const { barRanges, customRanges, redMin } = ranges[this.config.queryParam] || {
                redMin: this.ranges.RED_MIN,
                barRanges: [[5, 0], [15, 5], [60, 20], [15, 80], [5, 95]],
                customRanges: [
                    [this.ranges.RED_MIN, this.ranges.YELLOW_MIN],
                    [this.ranges.YELLOW_MIN, this.ranges.GREEN_MIN],
                    [this.ranges.GREEN_MIN, this.ranges.GREEN_MAX],
                    [this.ranges.GREEN_MAX, this.ranges.YELLOW_MAX],
                    [this.ranges.YELLOW_MAX, this.ranges.RED_MAX]
                ],
            }
            return this.data.map(({ x, y }) => {
                const i = customRanges.findIndex(([min, max]) => y >= min && y <= max)
                if (i === -1) return { x, y: redMin > y ? 0 : 100 }
                const [min, max] = customRanges[i]
                const [width, offset] = barRanges[i]
                return { x, y: (y - min) * 100 / (max - min) * width / 100 + offset }
            })
        },

        ranges () {
            return this.config.floatRanges || this.config.intRanges
        },

        chartData () {
            return {
                datasets: [
                    {
                        showLine: true,
                        pointBorderColor: this.bgColors,
                        pointBackgroundColor: this.bgColors,
                        pointRadius: 2,
                        data: this.dataFiltered

                    }
                ]
            }
        },

        options () {
            return deepmerge(options, {
                tooltips: {
                    enabled: true,
                    mode: 'nearest',
                    intersect: false,
                    callbacks: {
                        label: point => {
                            if (this.labels.length === 13) {
                                return ' Average: ' + this.data[point.index].y
                            } else {
                                return ' ' + format(new Date(this.data[point.index].t), 'hh:mma').toLowerCase() + ' ' + this.data[point.index].y
                            }
                        }
                    }
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                borderDash: this.labels.length !== 169 && this.labels.length !== 13 ? [2, 2] : null,
                                color: this.labels.map(item => {
                                    return item && this.labels.length !== 169 && this.labels.length !== 13 ? '#00000000' : '#5C8FB1'
                                }),
                                zeroLineWidth: 0,
                                lineWidth: 1
                            },
                            ticks: {
                                fontColor: '#fff',
                                autoSkip: false,
                                fontSize: 16,
                                maxRotation: 0,
                                min: 0,
                                max: this.labels.length - 1,
                                stepSize: 1,
                                callback: (value, index) => {
                                    if (this.labels.length === 169) {
                                        if (index % 24 === 0) {
                                            return this.labels[index].substring(0, 3)
                                        }
                                    } else {
                                        return this.labels[index]
                                    }
                                }
                            }
                        }
                    ],
                    yAxes: [
                        {
                            stacked: true,
                            gridLines: {
                                // color: 'red',
                                display: false,
                                // borderDash: [2, 2],
                                // color: ['#00000000', '#5C8FB1', '#00000000', '#5C8FB1', '#00000000', '#5C8FB1', '#00000000', '#5C8FB1'],
                                zeroLineColor: '#00000000',
                                // lineWidth: 1
                            },
                            ticks: {
                                display: false,
                                min: 0,
                                max: 100,
                                stepSize: 100 / 8
                            }
                        }
                    ]
                }
            })
        }
    }
}
</script>

<style scoped>

</style>
