import awsConfigProd from './aws-exports.prod'
import awsConfig from './aws-exports'

const PROD_DOMAIN = 'waterguru.com'

const MODE = (location.origin.includes(PROD_DOMAIN) && process.env.NODE_ENV === 'production') ? 'prod' : 'qa'
const PREFIX = (MODE === 'prod') ? 'prod-' : 'qa-'
const CONFIG = (MODE === 'prod') ? awsConfigProd : awsConfig
const DOMAIN = MODE === 'prod' ? 'https://waterguru.com' : 'http://store.waterguru-qa.com'

export { PREFIX, MODE, PROD_DOMAIN, DOMAIN }
export default CONFIG
