<template>
    <div class="version">Advice v{{ version }}</div>
</template>

<script>
import { version } from '../../package'

export default {
    name: 'Version',
    data () {
        return {
            version
        }
    }
}
</script>

<style scoped>

</style>
