<template>
    <div class="page-end-line"></div>
</template>

<script>
export default {
    name: 'PageEnd'
}
</script>

<style scoped lang="scss">

</style>
