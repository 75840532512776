<template>
    <button v-if="!to" class="btn btn-primary" :class="classes" @click="$emit('click', $event)" :disabled="disabled">
        <slot></slot>
    </button>
    <router-link v-else class="btn btn-primary" :class="classes" :to="to" @click="$emit('click', $event)">
        <slot></slot>
    </router-link>
</template>

<script>
export default {
    name: 'Button',
    props: {
        size: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'default'
        },
        to: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classes () {
            const arr = []
            if (this.size === 'xs')
                arr.push('btn-xs')
            else if (this.size === 'sm')
                arr.push('btn-sm')
            else if (this.size === 'lg')
                arr.push('btn-lg')
            else if (this.size === 'xl')
                arr.push('btn-xl')

            if (this.type === 'circle')
                arr.push('btn-circle')
            else if (this.type === 'filled')
                arr.push('btn-filled')
            else if (this.type === 'circle-primary')
                arr.push('btn-circle-primary')

            if (this.disabled)
                arr.push('disabled')

            return arr
        }
    }
}
</script>

<style scoped>

</style>
