<template>
    <div class="advice-alerts">
        <alert v-for="alert in alerts" :key="alert.key" :alert="alert"/>
    </div>
</template>

<script>
import Alert from './Alert'
export default {
    name: 'Alerts',
    components: { Alert },
    props: {
        alerts: {
            type: Array,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
