<template>
    <div class="d-flex flex-column align-items-center">
        <p v-html="macros(links(object.text))"></p>
        <button class="btn btn-primary" @click="submit(object.value)">
            Next
        </button>
    </div>
</template>

<script>
export default {
    name: 'HtmlButton',
    props: {
        object: {
            type: Object,
            required: true
        }
    },
    methods: {
        submit (value) {
            this.$emit('submit', { step: this.object.next, value })
        }
    }
}
</script>

<style scoped>
    p {
        white-space: pre-line;
    }
</style>
