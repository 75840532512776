import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import '../../../shared/components'
import { extend } from 'vee-validate'
import { min_value, max_value, between, required } from 'vee-validate/dist/rules.js'
import VueGtag from 'vue-gtag'
import router from '../router'

// Install rules
extend('min_value', min_value)
extend('max_value', max_value)
extend('between', between)
extend('required', required)

Vue.use(VueGtag, {
    config: { id: location.host === 'advice.waterguru.com' ? 'G-B9KEKP6182' : 'G-HBYWMSB8L9' }
}, router)

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://5133a7e618d54c7ea6a77db721013abb@sentry.io/1478287',
        integrations: [new Integrations.Vue({ Vue, attachProps: true })]
    })
}
