<template>
    <h1 class="advice-title">
        <slot></slot>
    </h1>
</template>

<script>
export default {
    name: 'Title'
}
</script>

<style scoped>

</style>
