<template>
    <div class="alert-circle" :style="{ backgroundColor: bgColor }"></div>
</template>

<script>
export default {
    name: 'AlertCircle',
    props: {
        status: {
            type: String,
            default: 'GREEN'
        }
    },
    computed: {
        bgColor () {
            const statusColors = this.$store ? this.$store.getters.statusColors : {
                GREEN: '#23B8DC',
                YELLOW: '#FAC504',
                RED: '#FF3B30'
            }
            return statusColors[this.status] || statusColors['GREEN']
        }
    }
}
</script>

<style scoped>

</style>
