<template>
    <div class="advice-picture">
        <img alt="" class="image" :src="src"/>
        <div class="text">{{ text }}</div>
    </div>
</template>

<script>
export default {
    name: 'Picture',
    props: {
        src: {
            required: true,
            type: String
        },
        text: {
            type: String
        },
    }
}
</script>

<style scoped>

</style>
