export const GA_HOME_PAGE_VIEW = 'home_page_view';
export const GA_COMPLETE_QUESTIONNAIRE = 'complete_questionnaire';
export const GA_EDIT_SETTINGS = 'edit_settings';
export const GA_CHECK_CHLORINE_ADVICE = 'check_chlorine_advice';
export const GA_CHECK_PH_ADVICE = 'check_ph_advice';
export const GA_HELP = 'help';
export const GA_WATERGURU_TEST = 'waterguru_test';
export const GA_CHLORINE_ADVICE_READ_MORE = 'chlorine_advice_read_more';
export const GA_PH_ADVICE_READ_MORE = 'ph_advice_read_more';
export const GA_WATERGURU_TEST_ITEM = 'waterguru_test_item';
export const GA_ADD_TO_CART = 'add_to_cart';
export const GA_VIEW_ITEM = 'view_item';
export const GA_VIEW_CART = 'view_cart';