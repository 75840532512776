import { format as format_fns } from 'date-fns'

const format = (pattern, state) => {
    // console.log(`FORMATTING pattern=${JSON.stringify(pattern)}`);
    // The pattern did not resolve successfully (the value is missing, see getItem() below that has || '' clause in it)
    if (pattern.value === '') {
      return pattern;
    }

    if (pattern.data_type === 'time' && pattern.format) {
        pattern.value = formatDate(pattern.value, pattern.format)
    }

    if (pattern.data_type === 'string' && pattern.format === 'capitalize') {
        pattern.value = pattern.value.charAt(0).toUpperCase() + pattern.value.slice(1)
    }

    if (pattern.data_type === 'number') {
        pattern.value = formatNumber(pattern.value)
    }

    if (pattern.formatter) {
        const value = pattern.formatter[pattern.value]
        if (typeof value === 'object') {
            const wbVal = getItem(state, value.path)
            pattern.value = value[wbVal]
        } else {
            pattern.value = value
        }
    }

    return pattern
}

const formatDate = (date, _format) => {
    try {
        return format_fns(new Date(date), _format)
    } catch (e) {
        return 'Invalid date'
    }
}

const formatNumber = (num) => {
    try {
        const str = num.toString().split('.')
        if (str[0].length >= 5) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,')
        }
        if (str[1] && str[1].length >= 5) {
            str[1] = str[1].replace(/(\d{3})/g, '$1 ')
        }
        return str.join('.')
    } catch (e) {
        return ''
    }
}

const getItem = (object, path) => {
    if (typeof path === 'function') {
        try {
            return path(object)
        } catch (e) {
            return ''
        }
    } else {
        return path.split('.').reduce((obj, item) => obj[item] || '', object)
    }
}

export default [
    {
        data_type: 'string',
        macro: 'FIRST_NAME',
        path: 'USER.attributes.given_name',
        format: 'capitalize'
    },
    {
        data_type: 'number',
        macro: 'CHLORINE',
        path: 'POOL.measurements.FREE_CL.value'
    },
    {
        data_type: 'number',
        macro: 'PH',
        path: 'POOL.measurements.PH.value'
    },
    {
        data_type: 'number',
        macro: 'TARGET_FC',
        path: 'POOL.freeClTargetEffective'
    },
    {
        data_type: 'number',
        macro: 'TARGET_PH',
        path: 'POOL.phTargetEffective'
    },
    {
        data_type: 'time',
        macro: 'FC_TIME',
        format: 'MM/dd/yyyy h:mm a',
        path: 'POOL.measurements.FREE_CL.measureTime'
    },
    {
        data_type: 'time',
        macro: 'PH_TIME',
        format: 'MM/dd/yyyy h:mm a',
        path: 'POOL.measurements.PH.measureTime'
    },
    {
        data_type: 'time',
        macro: 'TA_TIME',
        format: 'MM/dd/yyyy h:mm a',
        path: 'POOL.measurements.TA.measureTime'
    },
    {
        data_type: 'time',
        macro: 'CYA_TIME',
        format: 'MM/dd/yyyy h:mm a',
        path: 'POOL.measurements.CYA.measureTime'
    },
    {
        data_type: 'time',
        macro: 'CH_TIME',
        format: 'MM/dd/yyyy h:mm a',
        path: 'POOL.measurements.CH.measureTime'
    },
    {
        data_type: 'time',
        macro: 'PHOSPHATES_TIME',
        format: 'MM/dd/yyyy h:mm a',
        path: 'POOL.measurements.PHOSPHATES.measureTime'
    },
    {
        data_type: 'time',
        macro: 'COPPER_TIME',
        format: 'MM/dd/yyyy h:mm a',
        path: 'POOL.measurements.COPPER.measureTime'
    },
    {
        data_type: 'time',
        macro: 'IRON_TIME',
        format: 'MM/dd/yyyy h:mm a',
        path: 'POOL.measurements.IRON.measureTime'
    },
    {
        data_type: 'time',
        macro: 'SALT_TIME',
        format: 'MM/dd/yyyy h:mm a',
        path: 'POOL.measurements.SALT.measureTime'
    },
    {
        data_type: 'number',
        macro: 'GALLONS',
        path: 'POOL.waterBody.sizeGallons'
    },
    {
        data_type: 'string',
        macro: 'TYPE_OF_LINING',
        path: 'POOL.waterBody.surface',
        formatter: {
            PLASTER: 'plaster',
            VINYL_LINER: 'vinyl Liner',
            FIBERGLASS: 'fiberglass',
            OTHER: 'other type'
        }
    },
    {
        data_type: 'string',
        macro: 'CHLORINE_TYPE',
        path: 'POOL.waterBody.userCl',
        formatter: {
            TRICHLOR: 'Trichlor',
            CL_LIQ: {
                path: 'POOL.waterBody.userClLiq',
                BLEACH: 'Bleach',
                CL_PRODUCT: 'Chlorinating Liquid'
            },
            DICHLOR: 'Dichlor',
            CAL_HYPO: 'Cal-Hypo',
            SALT_CELL: 'Salt Cell'
        }
    },
    {
        data_type: 'string',
        macro: 'ACID_TYPE',
        path: 'POOL.waterBody.userAcid',
        formatter: {
            MURIATIC: 'muriatic acid',
            BISULFATE: 'dry acid',
            SULFURIC: 'sulfuric acid',
            OTHER: 'other type acid'
        }
    },
    {
        data_type: 'number',
        macro: 'TA',
        // path: 'LAB.measurements.3.value',
        path: state => {
            const meas = state.LAB.measurements.find(item => item.type === 'TA')
            return meas && meas.value
        }
    },
    {
        data_type: 'number',
        macro: 'TARGET_TA',
        path: 'LAB.taTargetEffective'
    },
    {
        data_type: 'number',
        macro: 'CYA',
        // path: 'LAB.measurements.5.value',
        path: state => {
            const meas = state.LAB.measurements.find(item => item.type === 'CYA')
            return meas && meas.value
        }
    },
    {
        data_type: 'number',
        macro: 'TARGET_CYA',
        path: 'LAB.cyaTargetEffective'
    },
    {
        data_type: 'number',
        macro: 'CH',
        // path: 'LAB.measurements.4.value',
        path: state => {
            const meas = state.LAB.measurements.find(item => item.type === 'CH')
            return meas && meas.value
        }
    },
    {
        data_type: 'number',
        macro: 'TARGET_CH',
        path: 'LAB.chTargetEffective'
    },
    {
        data_type: 'number',
        macro: 'PHOSPHATES',
        // path: 'LAB.measurements.6.value'
        path: state => {
            const meas = state.LAB.measurements.find(item => item.type === 'PHOSPHATES')
            return meas && meas.value
        }
    },
    {
        data_type: 'number',
        macro: 'SALT',
        // path: 'LAB.measurements.7.value'
        path: state => {
            const meas = state.LAB.measurements.find(item => item.type === 'SALT')
            return meas && meas.value
        }
    },
    {
        data_type: 'number',
        macro: 'COPPER',
        // path: 'LAB.measurements.8.value'
        path: state => {
            const meas = state.LAB.measurements.find(item => item.type === 'COPPER')
            return meas && meas.value
        }
    },
    {
        data_type: 'number',
        macro: 'IRON',
        // path: 'LAB.measurements.9.value',
        path: state => {
            const meas = state.LAB.measurements.find(item => item.type === 'IRON')
            return meas && meas.value
        }
    },
    {
        data_type: 'string',
        macro: 'CHLORINE_UNIT',
        path: 'POOL.measurements.FREE_CL.cfg.unit'
    }
]

export {
    format,
    getItem
}
