import Cookie from 'js-cookie'
import { isAfter } from 'date-fns'
import { DOMAIN } from '../amplify/config'

const productSuggestions = Cookie.get('product_suggestions') ? JSON.parse(Cookie.get('product_suggestions')) : null
const productsBought = Cookie.get('products_bought') ? JSON.parse(Cookie.get('products_bought')) : []
const exploded = DOMAIN.split('.')
const cookieDomain = '.' + exploded.slice(exploded.length - 2,3).join('.')

const initCookies = (date, allLabProducts) => {
    if (!productSuggestions || isAfter(new Date(date), new Date(productSuggestions.date))) {
        Cookie.set('product_suggestions', {
            date, ids: allLabProducts.map(({ id }) => id).join(',')
        }, { expires: 30, domain: cookieDomain })
    }
    return productSuggestions || { date, ids: allLabProducts.map(({ id }) => id).join(',') }
}

export {
    initCookies,
    productSuggestions,
    productsBought
}
