<template>
    <div class="separator"></div>
</template>

<script>
export default {
    name: 'Separator'
}
</script>

<style scoped>

</style>
