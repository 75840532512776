<template>
    <span class="beta-badge">[ BETA ]</span>
</template>

<script>
export default {
    name: 'BetaBadge'
}
</script>

<style scoped>

</style>
