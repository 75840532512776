<template>
    <div class="px-3">
        <h2 class="text-center">{{ object.title }}</h2>
        <p class="text-center">{{ object.text }}</p>
    </div>
</template>

<script>
export default {
    name: 'Message',
    props: {
        object: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
