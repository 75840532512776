<template>
    <div class="product-panel">
        <div class="img-box">
            <img :src="product.thumb" alt="">
        </div>
        <div class="description" @click="go">
            <span class="title">{{ product.name }}</span>
            <span class="price">${{ product.price }}</span>
        </div>
        <div class="button-box">
            <w-button @click="go" type="circle" size="lg" class="yummo-bold font-lg">{{ $t("button.buy") }}</w-button>
        </div>
    </div>
</template>

<script>
import { DOMAIN, MODE } from '../amplify/config'
import {GA_VIEW_CART} from "../constants/ga_events"

export default {
    name: 'ProductPanel',
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    methods: {
        go () {
            if (this.$route.params.category === 'free_cl'){
                this.$gtag.event('Add to cart - free CL advice');
            }

            if (this.$route.params.category === 'ph'){
                this.$gtag.event('Add to cart - pH advice');
            }

            this.$gtag.event(GA_VIEW_CART, {
                name: this.product.name,
                sku: this.product.sku
            })

            const id = MODE === 'prod' ? this.product.id : this.product.id_qa

            location.href = `${DOMAIN}/cart/add?id[]=${id}`
        }
    }
}
</script>

<style scoped>

</style>
