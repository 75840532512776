<template>
    <i :class="`${prefix} fa-${icon}`" @click="$emit('click', $event)"/>
</template>

<script>
export default {
    name: 'Fa',
    props: {
        icon: {
            type: String,
            required: true
        },
        prefix: {
            type: String,
            default: 'fa'
        },
    }
}
</script>

<style scoped>

</style>
