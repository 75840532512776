import { render, staticRenderFns } from "./LabResults.vue?vue&type=template&id=e4d8f172&scoped=true"
import script from "./LabResults.vue?vue&type=script&lang=js"
export * from "./LabResults.vue?vue&type=script&lang=js"
import style0 from "./LabResults.vue?vue&type=style&index=0&id=e4d8f172&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4d8f172",
  null
  
)

export default component.exports