<template>
    <div class="lab-header">
        <img width="165" height="43" src="../../resources/images/WaterGuru-TEST-Logo-White.svg" alt="">
        <div v-if="date">
            <div>{{ $t("lab.resultsDate") }}</div>
            <div>{{ date }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Header',
    props: {
        date: {
            type: String
        }
    }
}
</script>

<style scoped>

</style>
