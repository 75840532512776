<template>
    <div>
        <h3 class="mx-3 mb-4">{{ object.title }}</h3>
        <ul class="select-list">
            <li v-for="item in object.options" :key="item.step" :class="{active: active === item.step}" @click="active = item.step">
                {{ item.label }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'SelectList',
    props: {
        object: {
            required: true
        }
    },
    data () {
        return {
            active: null
        }
    },
    methods: {
        submit () {
            const curStep = this.object.options.find(item => item.step === this.active)
            this.$emit('submit', { step: curStep.step, value: curStep.value })
        }
    }
}
</script>

<style scoped lang="scss">

</style>
