<template>
    <div class="w-modal" :class="{ open, 'bottom': position === 'bottom', reverse: reverseColors }">
        <div class="header">
            <div v-if="showBack" class="back" @click="$emit('back', $event)">
                <w-icon name="chevron-right"/>
            </div>
            <span class="cancel" @click="$emit('cancel', $event)"><span class="close-icon">×</span> {{ $t("button.close") }}</span>
        </div>
        <div class="body">
            <slot/>
        </div>
    </div>
</template>

<script>
const body = document.querySelector('body')
export default {
    name: 'Modal',
    props: {
        open: {
            type: Boolean,
            required: true
        },
        cancelText: {
            type: String,
            default: 'Cancel'
        },
        showBack: {
            type: Boolean,
            default: true
        },
        position: {
            type: String,
            default: 'side'
        },
        reverseColors: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        open: {
            handler (open) {
                if (open) {
                    body.classList.add('modal-open')
                } else {
                    body.classList.remove('modal-open')
                }
            }
        }
    },
    created () {
        if (!this.open) body.classList.remove('modal-open')
    },
    destroyed () {
        body.classList.remove('modal-open')
    }
}
</script>

<style scoped>

</style>
