<template>
    <div class="d-flex flex-column align-items-center">
        <div class="custom-control custom-radio w-100" v-for="(item, index) in object.options" :key="item.value">
            <input type="radio" :id="`radio_${index}`" name="value" :value="item.value" v-model="value" class="custom-control-input">
            <label class="custom-control-label" :for="`radio_${index}`">{{ item.label }}</label>
        </div>
        <button class="btn btn-primary mt-2" @click="submit">
            Next
        </button>
    </div>
</template>

<script>
export default {
    name: 'RadioList',
    props: {
        object: {
            required: true
        }
    },
    data() {
        return {
            value: null
        }
    },
    methods: {
        submit() {
            this.$emit('submit', this.value)
        }
    }
}
</script>

<style scoped>

</style>
