import Vue from 'vue'
import Router from 'vue-router'
import { afterFilter, beforeFilter } from './filter'
import routes from './routes'

Vue.use(Router)

const router = new Router({
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes
})

router.beforeEach(beforeFilter)

router.afterEach(afterFilter)

export default router
