<template>
    <div class="lds-dual-ring"></div>
</template>

<script>
export default {
    name: 'Loader'
}
</script>

<style scoped lang="scss">
    .lds-dual-ring {
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        &:after {
            content: " ";
            display: block;
            width: 46px;
            height: 46px;
            margin: 1px;
            border-radius: 50%;
            border: 5px solid;
            border-color: #0098C5 transparent #0098C5 transparent;
            animation: lds-dual-ring 1.2s linear infinite;
        }
    }

    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
