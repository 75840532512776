<template>
    <div class="d-flex flex-column p-3">
        <h3 class="align-self-center">{{ object.title }}</h3>
        <div class="d-flex justify-content-around">
            <button class="btn btn-primary w-25" @click="submit('YES')">
                Yes
            </button>
            <button class="btn btn-primary w-25" @click="submit('NO')">
                No
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'YesNo',
    props: {
        object: {
            required: true
        }
    },
    methods: {
        submit(value) {
            this.$emit('submit', value)
        }
    }
}
</script>

<style scoped>

</style>
