<template>
    <h2 class="px-3">{{ $store.getters.error }}</h2>
</template>

<script>
export default {
    name: 'Error'
}
</script>

<style scoped>

</style>
