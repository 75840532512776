import Vuex from 'vuex'
import Vue from 'vue'
import questions from '../assets/json/questions'
import patterns, { format, getItem } from './patterns'
import { getUser, getPool, getLabResults } from '../api'
import { DOMAIN } from '../amplify/config'
import { initCookies, productsBought } from '../plugins/initCookies'
import { differenceInDays } from 'date-fns'
import moment from "moment";

Vue.use(Vuex)

const getEmptyState = () => {
  return {
                 USER: null,
                 POOL: null,
                 POOLS: null,
                 LAB: null,
                 PREV_ROUTE: null,
                 BACK_ROUTE: null,
                 START_ROUTE: null,
                 STATUS_COLORS: [],
                 ERROR: '',
                 LOADING: false
  }
}

const store = new Vuex.Store({
    state: getEmptyState(),
    mutations: {
        CLEAR_WITHOUT_ROUTES(state) {
          // Clear all data, but the routes (otherwise, we will lose our place in the app
          // and e.g. will just return to the home screen instead of back navigation!)
          const prevRoute = state.PREV_ROUTE;
          const backRoute = state.BACK_ROUTE;
          const startRoute = state.START_ROUTE;

          console.log("****** CLEARING WITHOUT ROUTES");
          // Merge rather than replace so we don't lose observers
          // https://github.com/vuejs/vuex/issues/1118
          Object.assign(state, getEmptyState())

          state.PREV_ROUTE = prevRoute;
          state.BACK_ROUTE = backRoute;
          state.START_ROUTE = startRoute;
        },

        SET_USER (state, user) {
            state.USER = user
        },
        SET_POOL (state, pool) {

            // In order to substitute the correct measurement time into the advice message,
            // adding a new key to the measurement object - the type of measured chemistry.
            // See patterns.js to find different patterns for each type of chemistry and steps.json to find how they are used.
            // Example: For CYA measurement pattern path looks like POOL.measurements.CYA.measureTime
            let poolCopy = Object.assign({}, pool);
            let measurements = poolCopy.measurements;
            if (measurements) { // Fails if no measurements yet! (Brand new pool)
                measurements.forEach((m) => {
                    measurements[m.type] = m;
                });
            }

            state.POOL = poolCopy
        },
        SET_POOLS (state, pools) {
            state.POOLS = pools
        },
        SET_PREV_ROUTE (state, route) {
            state.PREV_ROUTE = route
        },
        SET_BACK_ROUTE (state, route) {
            state.BACK_ROUTE = route
        },
        SET_START_ROUTE (state, route) {
            state.START_ROUTE = route
        },
        SET_STATUS_COLORS (state, colors) {
            state.STATUS_COLORS = colors
        },
        SET_ERROR (state, error) {
            state.ERROR = error
        },
        SET_LAB (state, lab) {
            state.LAB = lab
        },
        SET_LOADING (state, loading) {
            state.LOADING = loading
        }
    },
    getters: {
        user: state => state.USER,

        userId: state => state.USER ? state.USER.username : null,

        pool: state => state.POOL,

        pools: state => state.POOLS,

        statusColors: state => state.STATUS_COLORS,

        alerts: state => {
            let alerts = [
                ...(state.POOL && state.POOL.alerts ? state.POOL.alerts : []),
                ...(state.LAB && state.LAB.alerts ? state.LAB.alerts.filter(({ source }) => !['FREE_CL', 'PH'].includes(source)) : [])
            ];

            let encoded = [];

            alerts.forEach(alert => {
                let object = JSON.stringify(alert);
                if (!encoded.includes(object)){
                    encoded.push(object);
                }
            });

            return encoded.map(alert => JSON.parse(alert));
        },

        waterBody: state => state.POOL ? state.POOL.waterBody : null,

        measurements: state => {
            return [
                ...(state.POOL && state.POOL.measurements ? state.POOL.measurements : []),
                ...(state.LAB && state.LAB.measurements ? state.LAB.measurements.filter(({ type }) => !['FREE_CL', 'PH'].includes(type)) : [])
            ]
        },

        patterns: state => patterns.map(item => format({ ...item, value: getItem(state, item.path) }, state)),

        prevRoute: state => state.PREV_ROUTE,

        backRoute: state => state.BACK_ROUTE,

        startRoute: state => state.START_ROUTE,

        missedQuestions: (state) => {
            const wb = state.POOL ? state.POOL.waterBody : null
            if (!wb) return false
            return questions.filter(item => {
                return !item.relation && !wb[item.key] || (item.questions && item.questions.some(answer => {
                    return answer.related && answer.related.some(key => {
                        return wb[item.key] === answer.value && (!answer.more || Object.keys(answer.more).every(key => {
                            return wb[key] === answer.more[key]
                        })) && !wb[key]
                    }) || Object.keys(answer.more || {}).some(key => {
                        return wb[item.key] === answer.value && !wb[key]
                    })
                }))
            })
        },

        error: state => state.ERROR,

        lab: state => state.LAB,

        loading: state => state.LOADING,

        products: (state, { lab, measurements }) => {
            if (!measurements || !measurements.length || !lab) return [];

            let products = measurements.reduce((acc, measurement) => {
                if (measurement.alerts){
                    return [...acc, ...measurement.alerts];
                }
                return acc;
            }, []).reduce((acc, alert) => {
                if (
                    alert.advice &&
                    alert.advice.action &&
                    alert.advice.action.addChemical &&
                    alert.advice.action.addChemical.shopProduct
                ){
                    let product = alert.advice.action.addChemical.shopProduct;

                    return [...acc, {
                        id: product.id.toString(),
                        id_qa: product.id.toString(),
                        name: product.productName,
                        price: product.price,
                        thumb: product.thumbUrl,
                        sku: product.sku,
                        type: ['FREE_CL', 'PH'].includes(alert.source) ? 'home' : 'lab'
                    }];
                }
                return acc;
            }, []);
            // NOTE: some products may be recommended several times (from different chemicals), make the list unique
            products = Object.values(
              products.reduce((tempMap, product) => {
                if (!tempMap[product.id]) {
                    tempMap[product.id] = product;
                }

                return tempMap;
               }, {})
            );

            const allLabProducts = products.filter(p => p.type === 'lab');
            const productSuggestions = initCookies(lab.labResultsTime, allLabProducts);
            const ids = productSuggestions.ids.split(',');
            const outdatedLab = moment().diff(moment(lab.labResultsTime), 'months') > 0;

            return products.filter(product => {
                const notBoughtBefore = productsBought.every(({ date, id }) => product.id !== id && differenceInDays(new Date(), Date(date)) > 30);

                return ((product.type === 'lab' && ids.includes(product.id) && !outdatedLab) || (product.type === 'home' && notBoughtBefore));
            });
        },

        labProducts: (state, { products, lab }) => {
            const outdated = moment().diff(moment(lab.labResultsTime), 'months') > 0;
            if (outdated) return []
            return products.filter(item => item.type === 'lab')
        },

        buyProductsLink: (state, { products }) => {
            return `${DOMAIN}/cart/add` + products.reduce((acc, { id }, index) => {
                return `${acc}${index ? '&' : ''}id[]=${id}`
            }, '?')
        },

        homeProducts: (state, { products, pool }) => {
            const outdated = differenceInDays(new Date(), new Date(pool.labResultsTime)) > 1
            if (outdated) return []
            return products.filter(item => item.type === 'home')
        }
    },
    actions: {
        async init ({ commit }, { wbId, userId, loadLab }) {
            console.log("********** INIT!!!");

            // NOTE: it seems that sometimes (for some customers) there is a race condition between setting cookies
            // in WGWebView in iOS/Android app and getting cookies in getUser() function,
            // so some customers observed "Missing refreshToken cookie" (see getUser()).
            // Thus, to allow WGWebView set cookies (by evaluating JavaScript 'document.cookie = ...'),
            // we slightly delay getting cookies so all such cookie setting will be done first
// TODO: remove completely, the issue appeared to be with the app (not setting cookies when going from dashboard alerts)
//            console.log("********** SLEEPING");
//            await new Promise(r => setTimeout(r, 100));

            console.log("********** GETTING USER");
            const user = await getUser()
            if (!user) return { user: false, pool: false }
            commit('SET_USER', user)

            console.log("********** GETTING DATA");
            const { pool, pools, colors } = await getPool(userId || user.username, wbId)
            if (!pool) return { user, pool: false }
            commit('SET_POOL', pool)
            commit('SET_POOLS', pools)
            commit('SET_STATUS_COLORS', colors)
            if (!loadLab) {
                console.log("********** INIT END (WITHOUT LAB)!!!");
                return { user, pool }
            }

            const lab = await getLabResults(userId || user.username, wbId)
            if (lab) {
                commit('SET_LAB', lab)
            }
            console.log("********** INIT END (WITH LAB)!!!");
            return { user, pool }
        },
        setPrevRoute ({ commit }, route) {
            commit('SET_PREV_ROUTE', route)
        },
        setBackRoute ({ commit }, route) {
            commit('SET_BACK_ROUTE', route)
        },
        setStartRoute ({ commit }, route) {
            commit('SET_START_ROUTE', route)
        },
        setError ({ commit }, error) {
            commit('SET_ERROR', error)
        },
        startLoading ({ commit }) {
            commit('SET_LOADING', true)
        },
        stopLoading ({ commit }) {
            commit('SET_LOADING', false)
        }
    }
})
export default store
