<template>
    <div class="wizard-buttons">
        <w-button v-if="showBack" size="lg" type="circle" class="yummo-semi-bold" @click="$emit('back', $event)" :disabled="disableBack">
             <slot name="back"></slot>
        </w-button>

        <w-button size="lg" type="circle" class="yummo-semi-bold" @click="$emit('next', $event)" :disabled="disableNext">
             <slot name="next"></slot>
        </w-button>
    </div>
</template>

<script>
export default {
    name: 'Buttons',
    props: {
        disableNext: {
            default: false,
            type: Boolean
        },
        disableBack: {
            default: false,
            type: Boolean
        },
        showBack: {
            default: false,
            type: Boolean
        }
    }
}
</script>

<style scoped>

</style>
