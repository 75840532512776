export default {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    layout: {
        padding: {
            top: 22
        }
    },
    elements: {
        line: {
            borderColor: '#fff',
            borderWidth: 2,
            tension: 0,
            backgroundColor: '#ffffff00'
        },
        point: {
            radius: 0,
            borderWidth: 0,
            backgroundColor: '#23B8DC',
            borderColor: '#23B8DC',
        }
    },
    scales: {
        xAxes: [],
        yAxes: []
    }
}

const ranges = {
    ph: {
        redMin: 6.5,
        barRanges: [
            [5, 0],
            [15, 5],
            [20, 20],
            [20, 40],
            [20, 60],
            [15, 80],
            [5, 95]
        ],
        customRanges: [
            [6.5, 7.3],
            [7.3, 7.5],
            [7.5, 7.6],
            [7.6, 7.6],
            [7.6, 7.7],
            [7.7, 7.9],
            [7.9, 8.5],
        ]
    },
    fc: {
        redMin: 0,
        barRanges: [
            [5, 0],
            [15, 5],
            [30, 20],
            [30, 50],
            [15, 80],
            [5, 95]
        ],
        customRanges: [
            [0, 1.6],
            [1.6, 2.6],
            [2.6, 4],
            [4, 6.4],
            [6.4, 9.4],
            [9.4, 10],
        ]
    }
}

export {
    ranges
}
