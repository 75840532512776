import API from "@aws-amplify/api";
import { PREFIX } from "./config";
import { version } from "../../../package";

export default (name, body = {}, method = "post") => {
	!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
	const path = PREFIX + name + "/invocations";
	body.clientType = "WEB_VIEW";
	body.clientView = "ADVICE";
	body.clientVersion = version;
	body.forClientType =
		navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
			? "IOS_APP"
			: "ANDROID_APP";
	return API[method]("lambda", path, { body });
};
