<template>
<div>
    <w-translate-text :text="text" :fromLanguage="'en'" :toLanguage="locale"></w-translate-text>
</div>
</template>

<script>
import i18n from '../i18n'

export default {
    name: 'Translate',
    props: {
        text: {
            type: String,
            default: ''
        }
    },
    computed: {
        locale () {
            return i18n.locale;
        }
    }
}
</script>

<style scoped>

</style>
