<template>
    <div class="wizard-progress">
        <div v-for="i in count" class="circle" :class="{ done: i < current, active: i === current }"></div>
    </div>
</template>

<script>
export default {
    name: 'Progress',
    props: {
        count: {
            required: true,
            type: Number
        },
        current: {
            required: true,
            type: Number
        }
    }
}
</script>

<style scoped lang="scss">

</style>
