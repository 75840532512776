<template>
    <div>
        <w-separator/>
        <h3 class="yummo-bold">{{ $t("charts.historyOf", { "chemical": cfg.longLabel }) }}</h3>
        <w-tabs class="mt-3" :tabs="tabs" :value="tab" @input="tabChanged"/>
        <div class="date-switcher">
            <fa icon="chevron-left" @click="prev" class="chevron left" :class="{ disabled: !isPrev }"/>
            {{ config.desc }}
            <fa icon="chevron-right" @click="next" class="chevron right" :class="{ disabled: this.config.offset >= 0 }"/>
        </div>
        <w-chart :tab="tab" :config="cfg" :data="chartData" :labels="customChartLabels" :bg-colors="bgColors"/>
        <div style="margin-left: 25px" class="mt-2">{{ config.desc }}</div>
    </div>
</template>

<script>

import configs from './tabs-config'
import { addWeeks, addMonths, addYears, format, differenceInSeconds } from 'date-fns'
import * as locales from 'date-fns/locale'


export default {
    name: 'TabsChart',
    props: {
        cfg: {
            type: Object
        },
        getHistory: {
            type: Function,
            required: true
        },
        colors: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            history: [],
            tab: 'week',
            offset: 1
        }
    },
    computed: {
        tabs() {
          return [
            { id: 'week', title: this.$i18n.tc("charts.periodWeek") },
            { id: 'month', title: this.$i18n.tc("charts.periodMonth") },
            { id: 'year', title: this.$i18n.tc("charts.periodYear") }
          ];
        },
        chartLabels () {
            const arr = ['']
            let d = this.config.start
            while (d <= this.config.end) {
                arr.push(format(d, this.config.format, { locale: locales[this.$i18n.locale] }))
                d = this.config.func(d, 1)
            }

            return arr
        },
        chartData () {
            // Calculate average if tab YEAR selected
            return this.tab === 'year' ? this.chartLabels.map((item, index) => {
                const items = this.history.filter(({ time }) => format(new Date(time), this.config.format, { locale: locales[this.$i18n.locale] }) === item)
                return {
                    x: index,
                    y: items.length ? (items.reduce((previous, current) => current.value + previous, 0) / items.length).toFixed(1) : null
                }
            }).filter(item => item.y !== null) : this.chartLabels.reduce((acc, item, index) => {
                const items = this.history.filter(({ time }) => format(new Date(time), this.config.format, { locale: locales[this.$i18n.locale] }) === item)
                return [...acc, ...items.map(({ value, time }) => ({ x: index, y: value, t: time }))]
            }, []).sort((a, b) => {
                if(a.x !== b.x) {
                    return 0
                } else {
                    differenceInSeconds(new Date(a.time), new Date(b.time))
                    // return (a.y - b.y)
                }
            })
        },
        bgColors () {
            const ranges = this.cfg.floatRanges || this.cfg.intRanges
            return this.chartData.map(item => {
                if (item.y <= ranges.GREEN_MAX && item.y >= ranges.GREEN_MIN) {
                    return this.colors.GREEN
                } else if (item.y <= ranges.YELLOW_MAX && item.y >= ranges.YELLOW_MIN) {
                    return this.colors.YELLOW
                } else {
                    return this.colors.RED
                }
            })
        },
        config () {
            return configs(this.$i18n.locale)[this.tab]
        },
        customChartLabels () {
            if (this.tab === 'month') {
                return this.chartLabels.map((item) => item === '1' || !(item % 5) ? item : '')
            } else if (this.tab === 'year') {
                return this.chartLabels.map(item => item.charAt(0))
            } else {
                return this.chartLabels
            }
        },
        isPrev () {
            let fun = null
            if (this.config.key === 'w') {
                fun = addWeeks
            } else if (this.config.key === 'M') {
                fun = addMonths
            } else {
                fun = addYears
            }

            return format(fun(this.config.start, -1), 'yyyy', { locale: locales[this.$i18n.locale] }) >= 2019
        }
    },
    methods: {
        async tabChanged (tab) {
            this.history = []
            this.tab = tab
            this.history = await this.getHistory(this.config.start, this.config.end)
        },
        prev () {
            if (this.isPrev) {
                this.config.offset--
                this.tabChanged(this.tab)
            }
        },
        next () {
            if (this.config.offset < 0) {
                this.config.offset++
                this.tabChanged(this.tab)
            }
        }
    },
    created () {
        this.tabChanged(this.tab)
    }
}
</script>

<style scoped>

</style>
