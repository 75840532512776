import {
    addWeeks,
    startOfWeek,
    endOfWeek,
    addMonths,
    startOfMonth,
    endOfMonth,
    addYears,
    addHours,
    addDays,
    startOfYear,
    endOfYear,
    format
} from 'date-fns'
import * as locales from 'date-fns/locale'


const date = new Date()

export default (localeName) => ({
    week: {
        offset: 0,
        format: 'eee hh a',
        key: 'w',
        func: addHours,
        get start () {
            return startOfWeek(addWeeks(date, this.offset))
        },
        get end () {
            return endOfWeek(addWeeks(date, this.offset))
        },
        get desc () {
            const SY = format(this.end, 'yyyy')
            const SM = format(this.start, 'MMM', { locale: locales[localeName]})
            const EM = format(this.end, 'MMM', { locale: locales[localeName]})
            const SD = format(this.start, 'd')
            const ED = format(this.end, 'd')
            return `${SM} ${SD}-${EM === SM ? '' : (EM + ' ')}${ED}, ${SY}`
        }
    },
    month: {
        offset: 0,
        format: 'd',
        key: 'M',
        func: addDays,
        get start () {
            return startOfMonth(addMonths(date, this.offset))
        },
        get end () {
            return endOfMonth(addMonths(date, this.offset))
        },
        get desc () {
            return format(this.end, 'MMMM', { locale: locales[localeName]})
        }
    },
    year: {
        offset: 0,
        format: 'MMM',
        key: 'y',
        func: addMonths,
        get start () {
            return startOfYear(addYears(date, this.offset))
        },
        get end () {
            return endOfYear(addYears(date, this.offset))
        },
        get desc () {
            return format(this.end, 'yyyy', { locale: locales[localeName]})
        }
    }
})
