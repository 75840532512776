<template>
    <div class="w-select" :class="{open}">
        <div ref="button" class="button" @click="open = !open">
            {{ selected ? selected.text : placeholder }}
            <fa class="chevron" icon="chevron-down"/>
        </div>
        <div class="options" :class="position">
            <div class="option" v-for="option in options" :key="option.value" @click="select(option.value)">{{ option.text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Select',
    props: {
        options: {
            required: true,
            type: Array
        },
        value: {
            type: Number | String
        },
        placeholder: {
            type: String,
            default: 'Select...'
        }
    },
    data () {
        return {
            open: false,
            position: 'top'
        }
    },
    methods: {
        select (value) {
            this.$emit('input', value)
            this.$emit('select', value)
            this.open = false
        }
    },
    watch: {
        open () {
            const rects = this.$refs.button.getBoundingClientRect()
            const win_height = window.innerHeight
            const all_height = (this.options.length * 41) + 60
            this.position = (rects.top + (all_height > 245 ? 245 : all_height)) > win_height ? 'top' : 'bottom'
        }
    },
    computed: {
        selected () {
            return this.options.find(item => item.value === this.value)
        }
    }
}
</script>

<style scoped lang="scss">

</style>
