import Vue from 'vue'
import store from '../store'
import { adviceGuard, wizardGuard } from './component-guards'

const macros = (value, not_found = null) => {
    const patterns = store.getters.patterns
    for (const i in patterns) {
        if (patterns.hasOwnProperty(i)) {
            const pattern = patterns[i]
            if (value.includes(`[${pattern.macro}]`) && pattern.value === '') {
//                console.log(`NOT FOUND ${pattern.macro} = ${pattern.value}, ${not_found}`);
                if (pattern.default) {
                    pattern.value = pattern.default
                } else if (not_found) {
                    for (const index in patterns) {
                        if (patterns.hasOwnProperty(index)) {
                            const pattern_2 = patterns[index]
                            not_found = not_found.split(`[${pattern_2.macro}]`).join(pattern_2.value)
                        }
                    }
                    return not_found
                }
            }

            value = value.split(`[${pattern.macro}]`).join(pattern.value)
        }
    }
    return value
}

const mixin = {
    methods: {
        links (str) {
            return str.replace(/\(link\)\[.*]/g, match => {
                const arr = JSON.parse(match.replace('(link)', '').split('\'').join('"'))
                return `<a href="/#/${arr[0]}">${arr[1]}</a>`
            })
        },

        linksReadMore (str) {
            return str.replace(/\(link_read_more\)\[.*]/g, match => {
                const arr = JSON.parse(match.replace('(link_read_more)', '').split('\'').join('"'))
                const id = arr[0]
                const text = arr[1]
                return `<a class="color-secondary-400 read-more-link" data-id="${id}" href="#">${text}</a>`
            })
        },

        macros (value, patterns, errorMessage) {
            return macros(value, patterns, errorMessage)
        },

        getItem (object, path, notFound = false) {
            return path.split('.').reduce((obj, item) => obj[item] || notFound, object)
        }
    }
}

Vue.filter('macros', macros)

Vue.mixin(mixin)

const isValidUuid = (uuid) => {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    return regex.test(uuid)
}

export {
    isValidUuid,
    wizardGuard,
    adviceGuard,
    macros,
    mixin
}
