<template>
    <div class="advice-status">
        <div class="badge" :style="{ fontSize: badgeFS }">{{ abbr }}</div>
        <div class="text"><slot></slot></div>
    </div>
</template>

<script>
export default {
    name: 'Status',
    props: {
        abbr: {
            required: true,
            type: String
        }
    },
    computed: {
        badgeFS() {
            if (this.abbr.length === 4) {
                return '30px'
            } else if(this.abbr.length === 3) {
                return '45px'
            } else {
                return '56px'
            }
        }
    }
}
</script>

<style scoped>

</style>
