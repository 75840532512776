<template>
    <div class="w-lab-recommendations" v-if="products.length || outdated">
        <div class="alert">
            <div>
                <w-alert-circle class="circle" :status="status"/>
            </div>
            <template v-if="outdated">
                {{ $t("lab.outdated") }}
            </template>
            <template v-else>
                {{ $t("lab.suggestedProducts") }}
            </template>

        </div>
        <p v-if="outdated" class="ml-4">
            {{ $t("lab.orderTestMessage") }}
        </p>
        <ul v-if="products.length && !outdated">
            <li v-for="item in products" :key="item.id">{{ item.name }}<!-- - 25 lbs--></li>
        </ul>
        <div class="add-to-cart">
            <div class="d-flex align-items-end" v-if="!outdated && products.find(({type}) => type === 'lab')">
                {{ $t("lab.validTill") }} {{ time }}.
            </div>
            <div :class="{'ml-auto': outdated || !products.find(({type}) => type === 'lab')}">
                <w-button type="circle" size="lg" class="yummo-bold" :class="{ test: outdated }" @click="go(link)">
                    {{ outdated ? $t('lab.orderTestButton') : $t('button.buy') }}
                </w-button>
            </div>
        </div>
    </div>
</template>

<script>
import {GA_ADD_TO_CART, GA_VIEW_ITEM} from "../../app/src/constants/ga_events";

export default {
    name: "Recommendations",
    props: {
        time: {
            type: String,
            required: true
        },
        status: {
            type: String,
            default: 'RED'
        },
        link: {
            type: String,
            required: true
        },
        products: {
            type: Array,
            default: () => []
        },
        outdated: {
            type: Boolean,
            default: false
        }
    },
    mounted(){
        this.$gtag.event(GA_VIEW_ITEM, {
            currency: "USD",
            value: this.products.reduce((total, { price }) => total + price, 0),
            items: this.products.map(product => ({
                    item_id: product.sku,
                    item_name: product.name,
                    quantity: 1,
                    currency: 'USD',
                    price: product.price,
                })
            )
        })
    },
    methods: {
        go (link) {
            if (this.$route.name === 'home'){
                this.$gtag.event('Add to cart - home screen');
            }

            if (this.$route.name === 'lab-results'){
                this.$gtag.event('Add to cart - WG TEST advice');
            }

            this.$gtag.event(GA_ADD_TO_CART, {
                currency: "USD",
                value: this.products.reduce((total, { price }) => total + price, 0),
                items: this.products.map(product => ({
                        item_id: product.sku,
                        item_name: product.name,
                        quantity: 1,
                        currency: 'USD',
                        price: product.price,
                    })
                )
            })

            location.href = link
        }
    }
}
</script>

<style scoped>
    .btn.test {
        line-height: 24px;
        font-size: 24px;
    }
</style>
