<template>
    <div class="home">
        <p class="setup-incomplete" v-if="disableButtons">
            <img src="../assets/images/Group-653.svg" alt="">
            Setup incomplete. No unit installed
        </p>
        <w-recommendations
            :products="products"
            :time="date('MM/dd/yy')"
            :link="buyProductsLink"/>
        <w-select-list>
            <li class="px-0" v-for="(category, index) in categories" :key="category.value"
                :class="{ 'border-top-0': index === 0 }" @click="openAdvice(category.value)">
                <div class="name flex-grow-1">
                    {{ category.text }}
                </div>
                <fa class="font-sm" icon="chevron-right" v-if="!disableButtons"/>
            </li>
            <li class="px-0 border-bottom-0" @click="openLab">
                <div class="name flex-grow-1">WaterGuru TEST</div>
                <fa class="font-sm" icon="chevron-right" v-if="lab && lab.measurements"/>
            </li>
        </w-select-list>
        <w-version/>
    </div>
</template>

<script>
import { adviceGuard } from '../helpers'
import { mapGetters } from 'vuex'
import categories from '../assets/json/steps'
import { format, addDays } from 'date-fns'
import {
    GA_CHECK_CHLORINE_ADVICE,
    GA_CHECK_PH_ADVICE,
    GA_HOME_PAGE_VIEW,
    GA_WATERGURU_TEST
} from "../constants/ga_events";
import Translate from '../components/Translate'

export default {
    name: 'Home',
    components: { Translate },
    mounted(){
        this.$gtag.event(GA_HOME_PAGE_VIEW)
    },
    data () {
        return {
            categories: categories.filter(item => item.show_in_home)
            .map(({ name, path }) => ({ text: name, value: path }))
        }
    },
    computed: {
        ...mapGetters(['pool', 'lab', 'products', 'buyProductsLink']),
        disableButtons () {
            return !this.pool.pods
        }
    },
    methods: {
        openAdvice (category) {
            if (!this.disableButtons) {
                if (category === 'free_cl'){
                    this.$gtag.event(GA_CHECK_CHLORINE_ADVICE)

	                this.$gtag.config({
		                send_page_view: false
	                })

	                this.$gtag.event('page_view', {
		                page_title: 'WG Advice - Chlorine',
		                page_location: window.location.href,
		                page_path: window.location.pathname
	                })
                }
                if (category === 'ph'){
                    this.$gtag.event(GA_CHECK_PH_ADVICE)

	                this.$gtag.config({
		                send_page_view: false
	                })

	                this.$gtag.event('page_view', {
		                page_title: 'WG Advice - pH',
		                page_location: window.location.href,
		                page_path: window.location.pathname
	                })
                }
                this.$router.push({ name: 'advice', params: { category } }).catch(e => e)
            }
        },
        openLab () {
            if (this.lab && this.lab.measurements) {
                this.$gtag.event(GA_WATERGURU_TEST)
                this.$gtag.event('Check TEST Advice')

	            this.$gtag.config({
		            send_page_view: false
	            })

	            this.$gtag.event('page_view', {
		            page_title: 'WG Advice - WaterGuru Test',
		            page_location: window.location.href,
		            page_path: window.location.pathname
	            })

                this.$router.push({ name: 'lab-results' }).catch(e => e)
            }
        },
        date (_format) {
            return this.lab && this.lab.labResultsTime
                ? format(addDays(new Date(this.lab.labResultsTime), 21), _format)
                : ''
        }
    },
    beforeRouteEnter (...args) {
        adviceGuard(...args)
    },
    beforeRouteUpdate (...args) {
        adviceGuard(...args)
    },
    created () {
        this.$store.dispatch('setStartRoute', this.$route)
    }
}
</script>

<style scoped lang="scss">
    img {
        width: 35px;
    }

    .name {
        font-size: 22px;
    }
</style>
