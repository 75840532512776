<template>
    <div class="line-graph">
        <div class="line-graph-labels" v-if="labels">
            <div class="line-graph-labels-min" :style="{ left: `${position(ranges.GREEN_MIN)}%` }">
                <span v-if="ranges.GREEN_MIN">{{ranges.GREEN_MIN}}</span>
            </div>
            <div class="line-graph-labels-max" :style="{ left: `${position(ranges.GREEN_MAX)}%` }">
                <!-- eslint-disable-next-line -->
                <span v-if="!ranges.GREEN_MIN"><</span>{{ ranges.GREEN_MAX }}
            </div>
        </div>
        <div class="line-graph-bar-line" :class="{ 'with-min': ranges.GREEN_MIN !== 0 }"></div>
        <div class="line-graph-point" :style="{ left: `${position(value)}%` }">
            <img width="10" height="9" src="../resources/images/Level-Indicator-white-web.svg" alt="">
            <div v-if="point" :style="`transform: translateX(${valuePosition}%)`">{{ value }} {{ unit }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LineGraph',
    props: {
        ranges: {
            type: Object,
            required: true
        },
        value: {
            type: Number | String,
            required: true
        },
        labels: {
            type: Boolean,
            default: true
        },
        point: {
            type: Boolean,
            default: true
        },
        unit: {
            type: String,
            default: ''
        }
    },
    methods: {
        position (value) {
            const barRanges = this.ranges.GREEN_MIN
                ? [[5, 0], [15, 5], [60, 20], [15, 80], [5, 95]]
                : [[0, 0], [0, 0], [80, 0], [15, 80], [5, 95]]
            const customRanges = [
                [this.ranges.RED_MIN, this.ranges.YELLOW_MIN],
                [this.ranges.YELLOW_MIN, this.ranges.GREEN_MIN],
                [this.ranges.GREEN_MIN, this.ranges.GREEN_MAX],
                [this.ranges.GREEN_MAX, this.ranges.YELLOW_MAX],
                [this.ranges.YELLOW_MAX, this.ranges.RED_MAX]
            ]
            const i = customRanges.findIndex(([min, max]) => value >= min && value <= max)
            if (i === -1) return this.ranges.RED_MIN > value ? 0 : 100
            const [min, max] = customRanges[i]
            const [width, offset] = barRanges[i]
            return (value - min) * 100 / (max - min) * width / 100 + offset
        }
    },
    computed: {
        valuePosition () {
            if (this.position(this.value) > 95) {
                return -25
            } else if (this.position(this.value) < 4) {
                return 25
            } else {
                return 0
            }
        }
    }
}
</script>

<style scoped>

</style>
