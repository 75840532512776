<template>
    <ul class="select-list">
        <template v-if="!$slots.default">
            <li v-for="(option, index) in options" :key="`${option.value}_${index}`" :class="{active: value === option.value}"
                @click.self="$emit('input', option.value)">
                <span @click.self="$emit('input', option.value)">{{ option.name }}</span>
                <span @click="$emit('icon-click', $event)" v-if="option.icon" class="icon"><fa :icon="option.icon"/></span>
            </li>
        </template>
        <slot v-if="!!$slots.default">

        </slot>
    </ul>
</template>

<script>
export default {
    name: 'SelectList',
    props: {
        options: {
            type: Array
        },
        value: {
            type: String | Number
        }
    }
}
</script>

<style scoped>

</style>
