<template>
    <div class="accordion" :class="{open}">
        <div @click="toggle" class="button">
            <slot name="button"></slot>
            <fa class="chevron" icon="chevron-down"/>
        </div>
        <div class="content">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Accordion',
    props: {
        open: {
            required: true,
            type: Boolean
        }
    },
    methods: {
        toggle () {
            // this.isOpen = !this.isOpen
            this.$emit('toggle', !this.open)
        }
    }
}
</script>

<style scoped lang="scss">

</style>
