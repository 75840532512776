<template>
    <ul class="advice-card" :class="{'no-number': noNumber}"><slot></slot></ul>
</template>

<script>
export default {
    name: 'List',
    props: {
        noNumber: {
            default: false,
            type: Boolean
        }
    }
}
</script>

<style scoped>

</style>
