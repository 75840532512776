import Vue from 'vue'

import YesNo from './YesNo'
import UserInput from './UserInput'
import SelectList from './SelectList'
import RadioList from './RadioList'
import HtmlAnswer from './HtmlAnswer'
import HtmlButton from './HtmlButton'
import Message from './Message'

const getAdviceComponent = type => {

    switch (type) {
        case 1:
            return YesNo
        case 2:
            return UserInput
        case 3:
            return SelectList
        case 4:
            return HtmlButton
        case 5:
            return RadioList
        case 6:
            return HtmlAnswer
        case 7:
            return Message
        default:
            return { template: '<h2>Not Found</h2>' }
    }
}
Vue.mixin({
    methods: {
        getAdviceComponent (type) {
            return getAdviceComponent(type)
        }
    }
})
export {
    getAdviceComponent
}
