import Vue from 'vue'
import Title from './Title'
import Alerts from './alert/Alerts'
import Status from './Status'
import List from './List'
import ListCard from './ListCard'
import Picture from './Picture'
import Buttons from './Buttons'
import End from './End'
import ReadMore from './ReadMore'

import './advice-components'
import TabsChart from './TabsChart'

Vue.component('advice-title', Title)
Vue.component('advice-alerts', Alerts)
Vue.component('advice-status', Status)
Vue.component('advice-list', List)
Vue.component('advice-list-card', ListCard)
Vue.component('advice-picture', Picture)
Vue.component('advice-buttons', Buttons)
Vue.component('advice-end', End)
Vue.component('advice-read-more', ReadMore)
Vue.component('advice-chart', TabsChart)
