<template>
    <div class="lab-item">
        <h3>{{ item.title }}</h3>
        <div class="lab-item-body">
            <p>
                {{ item.alert }}
                <a v-if="!item.advice && item.desc" @click.prevent="$emit('click-modal')">More</a>
            </p>
<!--            <img @click="$emit('click-advice')" v-if="item.advice" src="../../resources/images/Chevron-Triple-White-Right.svg" alt="">-->
            <div @click="$emit('click-advice')" v-if="item.advice" class="lab-chevron-right">
                <fa icon="chevron-right"/>
            </div>
        </div>
        <w-line-graph :ranges="item.ranges" :value="item.value"/>
        <!--<div class="lab-resolved">-->
        <!--    Resolved this?-->
        <!--    <div class="lab-check-mark" :class="{ active: item.resolved }">-->
        <!--        <img src="../../resources/images/Check-Mark-Blue.svg" alt="">-->
        <!--    </div>-->
        <!--</div>-->
    </div>
</template>

<script>

export default {
    name: 'Item',
    props: {
        item: {
            type: Object
        }
    }
}
</script>

<style scoped>

</style>
