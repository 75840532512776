<template>
    <img :src="src" :srcset="srcset" :alt="name">
</template>

<script>

const images = {
    'chevron-right': {
        '1x': require('../resources/images/Chevron-Arrow-Back.png'),
        '2x': require('../resources/images/Chevron-Arrow-Back@2x.png'),
        '3x': require('../resources/images/Chevron-Arrow-Back@3x.png')
    }
}

export default {
    name: 'Icon',
    props: {
        name: {
            required: true,
            type: String
        }
    },
    computed: {
        src () {
            return images[this.name]['1x']
        },
        srcset () {
            return `${images[this.name]['1x']}, ${images[this.name]['2x']} 2x, ${images[this.name]['3x']} 3x`
        }
    }
}
</script>

<style scoped>

</style>
