<template>
    <div>
        <template v-for="item in readeMoreBlocks">
            <accordion v-if="item.type === 'accordion'" :id="`read_more_${item.id}`" :key="item.id" :open="open === item.id" @toggle="$event ? history.push(item.id) : (history = [])">
                <template v-slot:button>{{ item.title }}</template>
                <template v-slot:content>
                    <ul v-if="item.text.length">
                        <li v-for="text in item.text" v-html="text"></li>
                    </ul>
                    <div v-if="item.content" v-html="item.content"></div>
                </template>
            </accordion>
            <w-modal v-if="item.type === 'modal'" :open="open === item.id" @back="history.pop()" @cancel="history = []" :id="`read_more_${item.id}`" :key="item.id">
                <h3>{{ item.title }}</h3>
                <ul>
                    <li v-for="text in item.text" v-html="text"></li>
                </ul>
            </w-modal>
        </template>
    </div>
</template>

<script>
    import {GA_CHLORINE_ADVICE_READ_MORE, GA_PH_ADVICE_READ_MORE} from "../../../app/src/constants/ga_events";

export default {
    name: 'ReadMore',
    props: {
        readeMoreBlocks: {
            required: true,
            type: Array
        },
        toggled: {
            default: null,
            type: Number | String
        }
    },
    data () {
        return {
            history: [],
            prev: false
        }
    },
    computed:{
        open() {
            if (this.history[this.history.length - 1]){
                if (this.$route.params.category === 'free_cl'){
                    this.$gtag.event(GA_CHLORINE_ADVICE_READ_MORE)
                }
                if (this.$route.params.category === 'ph'){
                    this.$gtag.event(GA_PH_ADVICE_READ_MORE)
                }
            }
            return this.history[this.history.length - 1] || false
        }
    },
    methods: {
        handler (e) {
            if (!e.target.matches('.read-more-link')) return
            e.preventDefault()
            this.history.push(parseInt(e.target.dataset.id))
        }
    },
    mounted () {
        const query_rm = parseInt(this.toggled)
        if (query_rm) {
            const el = document.getElementById(`read_more_${query_rm}`)
            const block = this.readeMoreBlocks.find(item => item.id === query_rm)
            if (el) {
                if (block.type === 'accordion')
                    scrollTo({ top: el.getBoundingClientRect().top, behavior: 'smooth' })
                this.history.push(query_rm)
            }
        }
        document.addEventListener('click', this.handler, false)
    },
    destroyed () {
        document.removeEventListener('click', this.handler)
    }
}
</script>

<style scoped>

</style>
