import { render, staticRenderFns } from "./Version.vue?vue&type=template&id=dbcb909c&scoped=true"
import script from "./Version.vue?vue&type=script&lang=js"
export * from "./Version.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbcb909c",
  null
  
)

export default component.exports