import axios from 'axios'
import { format } from 'date-fns'
import { MODE } from '../amplify/config'

const PROD_URL = 'https://hooks.slack.com/services/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'
const DEV_URL = 'https://hooks.slack.com/services/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'
const URL = MODE === 'prod' ? PROD_URL : DEV_URL

export default (response, request = null, allCookies = {}) => {
    const text =
        '*response* ' + '```' + JSON.stringify(response, null, 2) + '``` ' +
        '*request* ' + '```' + JSON.stringify(request, null, 2) + '``` ' +
        '*cookies* ' + '```' + JSON.stringify(allCookies, null, 2) + '``` ' +
        '*url* ' + '`' + location.href + '` \n' +
        '*user agent* ' + '`' + navigator.userAgent + '` \n' +
        '*' + format(new Date(), 'MMMM d, yyyy h:mm aa') + '*'
    axios.post(URL, JSON.stringify({ text }))
}
