<template>
    <div class="advice-alert">
        <w-alert-circle :status="alert.status"/>
        {{ alert.text }}
    </div>
</template>

<script>

export default {
    name: 'Alert',
    props: {
        alert: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
