<template>
    <div id="app">
        <div :class="{'pt-5':$route.query.inline !== '1'}">
            <div class="h-100 d-flex justify-content-center">
                <div style="max-width: 768px;" class="w-100">
                    <router-link to="/" v-if="$route.meta.header && $route.query.inline !== '1'">
                        <advice-title class="mx-3 mb-3">
                            {{ $t("header.title") }}
                            <fa v-if="$route.name === 'home'" @click="help()" class="font-lg" icon="question-circle" prefix="far"/>
                        </advice-title>
                    </router-link>
                    <router-view v-if="!loading"/>
                    <loader v-else/>
                </div>
            </div>
        </div>
        <w-modal :open="modal" position="bottom" :cancel-text="$t('button.close')" :show-back="false" :reverse-colors="true" @cancel="modal = false">
            <h3 class="nunito text-center mb-4">{{ $t("help.title") }}</h3>
            <p>{{ $t("help.line1") }}</p>
            <p>{{ $t("help.line2") }}</p>
        </w-modal>
    </div>
</template>

<script>
import Loader from './components/Loader'
import { mapGetters } from 'vuex'
import {GA_HELP} from "./constants/ga_events";
import {MODE} from "./amplify/config";
import Cookies from 'js-cookie'

export default {
    components: { Loader },
    computed: {
        ...mapGetters(['loading'])
    },
    data () {
        return {
            modal: false
        }
    },
    methods: {
        help(){
            this.modal = true
            this.$gtag.event(GA_HELP)
        },
        setCookie() {
            const domain = MODE === 'prod' ? '.waterguru.com' : '.waterguru-qa.com';

            console.log(domain)
            Cookies.set('wg_advice', true, { domain, path: '/' })
        }
    },
    created () {
        this.setCookie();
        const meta = document.querySelector('meta[http-equiv="x-dns-prefetch-control"]')
        if (meta) {
            const link = document.createElement('link')
            link.rel = 'dns-prefetch'
            link.href = '//' + location.host
            meta.parentNode.insertBefore(link, meta.nextSibling)
        }
    }
}
</script>

<style lang="scss">
    @import "../../shared/resources/scss/index";

    /* iPhone, portrait & landscape. */
    @media all and (max-device-width: 480px) {
        html, body {
            -webkit-text-size-adjust: none;
        }
    }

    /* iPad, portrait & landscape. */
    @media all and (min-device-width: 768px) and (max-device-width: 1024px) {
        html, body {
            -webkit-text-size-adjust: none;
        }
    }

    html, body {
        height: 100%;
        background-color: $primary-color;
        -ms-text-size-adjust: none;
        -moz-text-size-adjust: none;
        -o-text-size-adjust: none;
        -webkit-text-size-adjust: none;

        #app {
            background-color: $primary-color;
            color: $text-color;
            min-height: 100%;
            overflow-x: hidden;
        }
    }

    h3 {
        margin-top: 20px;
    }
</style>
