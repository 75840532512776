const UNAUTHENTICATED = 'Missing authenticated user session'
const WB_ID_MISSING = 'Missing WaterBody id'
const POOL_NOT_FOUND = 'WaterBody id does not belong to user'
const ADD_POOL = 'Please add a pool to your account to enable advice'

export {
    UNAUTHENTICATED,
    WB_ID_MISSING,
    ADD_POOL,
    POOL_NOT_FOUND
}
