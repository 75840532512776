<template>
    <ul class="tabs">
        <li v-for="tab in tabs" :key="tab.id" :class="{active: tab.id === value}" @click="input(tab.id)">
            {{ tab.title }}
        </li>
    </ul>
</template>

<script>
export default {
    name: 'Tabs',
    props: {
        tabs: {
            required: true,
            type: Array
        },
        value: {
            type: String
        }
    },
    methods: {
        input (id) {
            if (this.value !== id) {
                this.$emit('input', id)
            }
        }
    }
}
</script>

<style scoped>

</style>
