<template>
    <div class="d-flex flex-column p-3">
        <ul>
            <li v-for="item in object.list" :key="item" v-html="item"></li>
        </ul>
        <input :type="object.type"
               :placeholder="object.placeholder"
               :step="object.step"
               :name="object.name"
               v-model="value">
        <!--        <div :class="{'d-block': errors.has(object.name)}" class="invalid-feedback mb-2 color-auxiliary-50">{{ errors.first(object.name) }}</div>-->
    </div>
</template>

<script>

export default {
    name: 'UserInput',
    props: {
        object: {
            required: true
        }
    },
    data () {
        return {
            value: ''
        }
    },
    methods: {
        submit (value) {
            // this.$validator.validateAll().then(result => {
            //     if (result)
            this.$emit('submit', { step: this.object.next, value })
            // })
        },
        payload () {

        }
    },

    // computed: {
    //     readMoreBlocks () {
    //         if (!this.object.read_more_ids) return []
    //         return readMoreBlocks.filter(item => ~this.object.read_more_ids.indexOf(item.id))
    //     }
    //
    // }
}
</script>

<style scoped>
    p {
        white-space: pre-wrap;
    }
</style>
