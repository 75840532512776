import lambda from './lambda'
import Amplify from '@aws-amplify/core';
import CONFIG from './config'

const ENDPOINT = `https://lambda.${CONFIG.region}.amazonaws.com/2015-03-31/functions/`
const REGION = CONFIG.region

// See https://docs.amplify.aws/gen1/javascript/tools/libraries/configure-categories/
// TODO: exports files do not work!!!
Amplify.configure({
    Auth: CONFIG,
    API: {
        endpoints: [
            {
                name: 'lambda',
                endpoint: ENDPOINT,
                service: 'lambda',
                region: REGION
            }
        ]
    },
    Predictions: {
      convert: {
        translateText: {
          region: 'us-west-2',
          proxy: false,
          defaults: {
            sourceLanguage: 'en',
            targetLanguage: 'fr'
          }
        }
      }
    }
});

export { lambda, CONFIG }
