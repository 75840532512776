import Vue from 'vue'
import Button from './Button'
import Accordion from './Accordion'
import Select from './select/Select'
import SelectList from './SelectList'
import Modal from './Modal'
import Icon from './Icon'
import PageEnd from './PageEnd'
import Chart from './chart/Chart'
import Tabs from './Tabs'
import BetaBadge from './BetaBadge'
import LineGraph from './LineGraph'
import Version from './Version'
import Separator from './Separator'
import Fa from './Fa'

import './advice'
import './wizard'
import './lab'
import AlertCircle from './AlertCircle'
import Recommendations from './Recommendations'

import TranslateText from './TranslateText'

Vue.component('fa', Fa)
Vue.component('w-button', Button)
Vue.component('w-select', Select)
Vue.component('w-select-list', SelectList)
Vue.component('w-icon', Icon)
Vue.component('accordion', Accordion)
Vue.component('w-modal', Modal)
Vue.component('w-chart', Chart)
Vue.component('w-page-end', PageEnd)
Vue.component('w-tabs', Tabs)
Vue.component('w-beta-badge', BetaBadge)
Vue.component('w-line-graph', LineGraph)
Vue.component('w-version', Version)
Vue.component('w-separator', Separator)
Vue.component('w-alert-circle', AlertCircle)
Vue.component('w-recommendations', Recommendations)
Vue.component('w-translate-text', TranslateText)
