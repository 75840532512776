import { mixins, Scatter } from 'vue-chartjs'

const drawLine = (ctx, x1, y1, x2, y2, dashed = false) => {
    ctx.setLineDash(dashed ? [2, 2] : [0, 0])
    ctx.beginPath()
    ctx.moveTo(x1, y1)
    ctx.lineTo(x2, y2)
    ctx.stroke()
}

const config = type => {
    return {
        drawHorizontalLines (ctx, chart, offset) {
            if (type === 'ph') {
                drawLine(ctx, 0, 22, chart.width - offset, 22)
                drawLine(ctx, 0, 53, chart.width - offset, 53, true)
                drawLine(ctx, 0, 82.5, chart.width - offset, 82.5)
                drawLine(ctx, 0, 112.5, chart.width - offset, 112.5, true)
                drawLine(ctx, 0, 142, chart.width - offset, 142, true)
                drawLine(ctx, 0, 172, chart.width - offset, 172)
                drawLine(ctx, 0, 201.5, chart.width - offset, 201.5, true)
            } else if (type === 'free_cl') {
                drawLine(ctx, 0, 22, chart.width - offset, 22)
                drawLine(ctx, 0, 48.5, chart.width - offset, 48.5, true)
                drawLine(ctx, 0, 75.5, chart.width - offset, 75.5)
                drawLine(ctx, 0, 102, chart.width - offset, 102, true)
                drawLine(ctx, 0, 128.5, chart.width - offset, 128.5)
                drawLine(ctx, 0, 155, chart.width - offset, 155, true)
                drawLine(ctx, 0, 182, chart.width - offset, 182)
                drawLine(ctx, 0, 208.5, chart.width - offset, 208.5, true)
            } else {
                drawLine(ctx, 0, 22, chart.width - offset, 22)
                drawLine(ctx, 0, 48.5, chart.width - offset, 48.5, true)
                drawLine(ctx, 0, 75, chart.width - offset, 75)
                drawLine(ctx, 0, 101, chart.width - offset, 101, true)
                drawLine(ctx, 0, 128, chart.width - offset, 128)
                drawLine(ctx, 0, 154, chart.width - offset, 154, true)
                drawLine(ctx, 0, 180, chart.width - offset, 180)
                drawLine(ctx, 0, 206.5, chart.width - offset, 206.5, true)
            }
        },
        drawGradient (grad) {
            grad.addColorStop(0, '#FF3B30')
            grad.addColorStop(.05, '#FF3B30')
            grad.addColorStop(.05, '#FAC504')
            grad.addColorStop(.2, '#FAC504')
            grad.addColorStop(.2, '#23B8DC')
            grad.addColorStop(.8, '#23B8DC')
            grad.addColorStop(.8, '#FAC504')
            grad.addColorStop(.95, '#FAC504')
            grad.addColorStop(.95, '#FF3B30')
            grad.addColorStop(1, '#FF3B30')
        }
    }
}

export default {
    name: 'Graphic',
    extends: Scatter,
    mixins: [mixins.reactiveProp],
    props: ['options'],
    watch: {
        options: {
            deep: true,
            handler () {
                this.renderChart(this.chartData, this.options)
            }
        }
    },
    mounted () {
        this.addPlugin({
            id: 'customize',
            afterEvent: (chart, e) => {
                const x = e.x
                const y = e.y

                const active = chart.getDatasetMeta(0).data.reduce((acc, cur, idx, src) => {
                    if (Math.abs(x - cur._model.x) < Math.abs(x - src[acc]._model.x)) {
                        return idx
                    } else if (Math.abs(x - cur._model.x) === Math.abs(x - src[acc]._model.x)) {
                        if (Math.abs(y - cur._model.y) < Math.abs(y - src[acc]._model.y)) {
                            return idx
                        } else {
                            return acc
                        }
                    } else {
                        return acc
                    }
                }, 0)

                if (chart.getDatasetMeta(0).data[active]) {
                    chart.tooltip._active = [chart.getDatasetMeta(0).data[active]]
                    chart.tooltip.update(true)
                }
            },
            beforeDatasetDraw: chart => {
                const ctx = chart.ctx
                const max = this.options.scales.xAxes[0].ticks.max
                let offset = 0
                ctx.strokeStyle = '#5C8FB1'
                const { drawHorizontalLines, drawGradient } = config(this.$route.params.category)

                // draw horizontal lines
                if (max === 168) {
                    offset = 15
                } else if (max === 12) {
                    offset = 9
                } else if (max === 31 || max === 28 || max === 29) {
                    offset = 3
                } else if (max === 30) {
                    offset = 12
                }

                drawHorizontalLines(ctx, chart, offset)

                // draw vertical lines
                if (max !== 168 && max !== 12) {
                    if (max === 31) {
                        offset = 13
                    } else if (max === 30) {
                        offset = 22
                    } else if (max === 28 || max === 29) {
                        offset = 14
                    }

                    const a = (chart.width - offset) / max
                    drawLine(ctx, 10 + a, 22, 10 + a, 243)
                    drawLine(ctx, 10 + (5 * a), 22, 10 + (5 * a), 243)
                    drawLine(ctx, 10 + (10 * a), 22, 10 + (10 * a), 243)
                    drawLine(ctx, 10 + (15 * a), 22, 10 + (15 * a), 243)
                    drawLine(ctx, 10 + (20 * a), 22, 10 + (20 * a), 243)
                    drawLine(ctx, 10 + (25 * a), 22, 10 + (25 * a), 243)
                    drawLine(ctx, 10 + ((max === 31 ? 30 : max) * a), 22, 10 + ((max === 31 ? 30 : max) * a), 243)
                }

                const grad = ctx.createLinearGradient(10, 21, 10, 234)

                drawGradient(grad)

                ctx.strokeStyle = grad
                ctx.lineWidth = 4
                drawLine(ctx, 10, 21, 10, 234)

                if (chart.tooltip._active && chart.tooltip._active.length) {
                    const point = chart.tooltip._active[0]
                    const x = point.tooltipPosition().x
                    const y1 = chart.scales['y-axis-1'].top
                    const y2 = chart.scales['y-axis-1'].bottom
                    ctx.strokeStyle = '#FFF'
                    ctx.lineWidth = 1
                    ctx.save()
                    drawLine(ctx, x, y1, x, y2)
                    ctx.restore()
                }
            }
        })
        this.renderChart(this.chartData, this.options)
    }
}

