<template>
    <div class="advice-buttons" :class="{'justify-content-end': !showBack, 'justify-content-start': !showContinue}">
        <w-button class="yummo-semi-bold bold"
                  v-if="showBack"
                  @click="$emit('back', $event)"
                  type="circle"
                  :disabled="disableBack"
                  size="lg">
            <slot name="backText"/>
        </w-button>
        <w-button class="yummo-semi-bold bold"
                  v-if="showContinue"
                  @click="$emit('continue', $event)"
                  :to="continueRoute"
                  type="circle"
                  :disabled="disableContinue"
                  size="lg">
            <slot name="continueText"/>
        </w-button>
    </div>
</template>

<script>
export default {
    name: 'Buttons',
    props: {
        showBack: {
            type: Boolean,
            default: true
        },

        disableBack: {
            type: Boolean,
            default: false
        },

        showContinue: {
            type: Boolean,
            default: true
        },

        disableContinue: {
            type: Boolean,
            default: false
        },

        continueRoute: {
            type: Object,
            default: null
        }
    }
}
</script>

<style scoped>

</style>
